import styled, { css } from "styled-components";

import themeColors, { ColorName } from "styles/config/colors";
import themeSpacing from "styles/config/spacing";
import text from "styles/config/text";
import transition from "styles/config/transition";
import UnstyledLoadingIcon from "../LoadingIcon";
import { BodyStrong } from "../Text";
import { ButtonLabelSize, ButtonVariant } from ".";

export const variantToButtonIconColor: {
  [key in keyof typeof ButtonVariant]: ColorName;
} = {
  BRAND: "iconButtonBrand",
  PRIMARY: "iconPrimaryInverse",
  SECONDARY: "iconSecondary",
  GHOST: "iconPrimary",
  SUCCESS: "iconPrimaryInverse",
  DANGER: "iconPrimaryInverse",
};

export const variantToInverseButtonIconColor: {
  [key in keyof typeof ButtonVariant]: ColorName;
} = {
  BRAND: "iconButtonBrand",
  PRIMARY: "iconButtonBrand",
  SECONDARY: "iconPrimaryInverse",
  GHOST: "iconPrimaryInverse",
  SUCCESS: "iconPrimaryInverse",
  DANGER: "iconPrimaryInverse",
};

const BrandStyles = css`
  color: ${themeColors.textButtonBrand};
  background: ${themeColors.backgroundBrand};

  &:hover {
    background: ${themeColors.backgroundBrandHover};
  }

  &:focus {
    box-shadow: inset 0 0 0 2px ${themeColors.borderFocus};
  }
`;

const BrandInverseStyles = css`
  ${BrandStyles}

  &:focus {
    box-shadow: inset 0 0 0 2px ${themeColors.borderInverseFocus};
  }
`;

const PrimaryStyles = css`
  color: ${themeColors.textPrimaryInverse};
  background: ${themeColors.backgroundPrimary};

  &:hover {
    background: ${themeColors.backgroundPrimaryHover};
  }

  &:focus {
    color: ${themeColors.textPrimaryInverse};
    box-shadow: inset 0 0 0 2px ${themeColors.borderFocus};
  }
`;

const PrimaryInverseStyles = css`
  ${PrimaryStyles}
  color: ${themeColors.textButtonBrand};
  background: ${themeColors.backgroundPrimaryInverse};

  &:hover {
    background: ${themeColors.backgroundPrimaryInverseHover};
  }

  &:focus {
    color: ${themeColors.textButtonBrand};
    box-shadow: inset 0 0 0 2px ${themeColors.borderInverseFocus};
  }
`;

const SecondaryStyles = css`
  color: ${themeColors.textPrimary};
  background: transparent !important;
  box-shadow: inset 0 0 0 1px ${themeColors.borderSubtle};

  &:hover {
    box-shadow: inset 0 0 0 1px ${themeColors.borderStrong};
  }

  &:focus {
    box-shadow: inset 0 0 0 2px ${themeColors.borderFocus};
  }

  &:disabled {
    &,
    &:hover,
    &:active,
    &:focus {
      background: transparent !important;
      box-shadow: inset 0 0 0 1px ${themeColors.borderSubtle};
    }
  }
`;

const SecondaryInverseStyles = css`
  ${SecondaryStyles}
  color: ${themeColors.textPrimaryInverse} !important;
  box-shadow: inset 0 0 0 1px ${themeColors.borderSubtleInverse};

  &:hover {
    background: ${themeColors.backgroundInverseHover} !important;
    box-shadow: inset 0 0 0 1px ${themeColors.borderStrongInverse};
  }

  &:focus {
    box-shadow: inset 0 0 0 2px ${themeColors.borderInverseFocus};
  }

  &:disabled {
    &,
    &:hover,
    &:active,
    &:focus {
      box-shadow: inset 0 0 0 1px ${themeColors.borderStrongInverse};
    }
  }
`;

const GhostStyles = css`
  color: ${themeColors.textPrimary};
  background: transparent !important;
  border: none;

  &:hover {
    background: ${themeColors.backgroundHover} !important;
  }

  &:focus {
    box-shadow: inset 0 0 0 2px ${themeColors.borderFocus};
  }
`;

const GhostInverseStyles = css`
  ${GhostStyles}
  color: ${themeColors.textPrimaryInverse} !important;

  &:hover {
    background: ${themeColors.backgroundInverseHover};
  }

  &:focus {
    box-shadow: inset 0 0 0 2px ${themeColors.borderInverseFocus};
  }

  &:disabled {
    &,
    &:hover,
    &:active,
    &:focus {
      background: transparent !important;
    }
  }
`;

const SuccessStyles = css`
  color: ${themeColors.textPrimaryInverse} !important;
  background: ${themeColors.backgroundSuccess};

  &:hover {
    background: ${themeColors.backgroundSuccessHover};
  }

  &:focus {
    box-shadow: inset 0 0 0 2px ${themeColors.borderFocus};
  }
`;

const SuccessInverseStyles = css`
  ${SuccessStyles};
  &:focus {
    box-shadow: inset 0 0 0 2px ${themeColors.borderInverseFocus};
  }
`;

const DangerStyles = css`
  color: ${themeColors.textPrimaryInverse} !important;
  background: ${themeColors.backgroundDanger};

  &:hover {
    background: ${themeColors.backgroundDangerHover};
  }

  &:focus {
    box-shadow: inset 0 0 0 2px ${themeColors.borderFocus};
  }
`;

const DangerInverseStyles = css`
  ${DangerStyles};
  &:focus {
    box-shadow: inset 0 0 0 2px ${themeColors.borderInverseFocus};
  }
`;

interface $ButtonProps {
  $variant?: keyof typeof ButtonVariant;
  $inverse?: boolean;
  $size?: ButtonLabelSize;
  $loading?: boolean;
  $fullWidth?: boolean;
  $hasIcon?: boolean;
  $disabled?: boolean;
  $show?: boolean;
}

export const ButtonWrapper = styled.button<$ButtonProps>`
  position: relative;

  padding: ${themeSpacing.xs2} ${themeSpacing.sm2};

  color: ${themeColors.textPrimaryInverse};
  text-align: center;

  background: ${themeColors.backgroundPrimary};
  border-radius: ${themeSpacing.sm2};

  transition: all 0.3s ${transition.easeSineOut};

  ${({ $size }) =>
    $size === "small" &&
    css`
      padding: ${themeSpacing.xs2} ${themeSpacing.sm2};
    `}

  ${({ $fullWidth }) =>
    $fullWidth &&
    css`
      width: 100%;
    `} 

  &:disabled {
    cursor: not-allowed;

    &,
    &:active,
    &:focus {
      color: ${({ $inverse }) =>
        $inverse ? themeColors.textInverseDisabled : themeColors.textDisabled};
      background: ${({ $inverse }) =>
        $inverse
          ? themeColors.backgroundAccentInverse
          : themeColors.backgroundDisabledSubtle};
    }

    &:hover {
      background: none !important;
    }
  }

  ${({ $variant, $inverse }) => {
    switch ($variant) {
      case "BRAND":
        return $inverse ? BrandInverseStyles : BrandStyles;
      case "PRIMARY":
        return $inverse ? PrimaryInverseStyles : PrimaryStyles;
      case "SECONDARY":
        return $inverse ? SecondaryInverseStyles : SecondaryStyles;
      case "GHOST":
        return $inverse ? GhostInverseStyles : GhostStyles;
      case "SUCCESS":
        return $inverse ? SuccessInverseStyles : SuccessStyles;
      case "DANGER":
        return $inverse ? DangerInverseStyles : DangerStyles;
      default:
        return PrimaryStyles;
    }
  }}
`;

export const IconOnlyButtonWrapper = styled(ButtonWrapper)`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;
  padding: 10px;

  &:disabled {
    cursor: not-allowed;

    &,
    &:active,
    &:focus {
      background: ${({ $inverse }) =>
        $inverse
          ? themeColors.backgroundAccentInverse
          : themeColors.backgroundDisabledSubtle};
    }

    &:hover {
      background: none !important;
    }
  }

  ${({ $size }) =>
    $size === "small" &&
    css`
      width: 32px;
      height: 32px;
      padding: 6px;
    `}
`;

export const ButtonLabel = styled(BodyStrong)<$ButtonProps>`
  position: relative;
  top: 50%;
  transform: translateY(-65%);
  min-width: fit-content;

  ${({ $size }) =>
    $size === "small" &&
    css`
      font-size: ${text.size.body.body4};
    `}

  ${({ $hasIcon }) =>
    $hasIcon &&
    css`
      margin: 0 ${themeSpacing.xs1};
    `}
  
  ${({ $disabled }) =>
    $disabled &&
    css`
      color: ${themeColors.textDisabled};
    `}
`;

export const ButtonContentContainer = styled.div<$ButtonProps>`
  display: flex;
  flex-direction: row;
  gap: ${themeSpacing.xs1};
  align-items: center;
  justify-content: center;

  height: ${themeSpacing.sm3};

  opacity: ${({ $show }) => ($show ? 1 : 0)};

  transition: opacity 0.1s ${transition.easeSineOut};

  ${({ $size }) =>
    $size === "small" &&
    css`
      height: 20px;
    `}
`;

export const LoadingIcon = styled(UnstyledLoadingIcon)<{ $show: boolean }>`
  position: absolute;
  top: 52%;
  left: 50%;
  transform: translate(-50%, -50%);

  opacity: ${({ $show }) => ($show ? 1 : 0)};

  transition: opacity 0.1s ${transition.easeSineOut};
`;
