export const hexToRGBArray = (hex: string) => {
  const rgbArr = hex
    .replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (m, r, g, b) => "#" + r + r + g + g + b + b
    )
    .substring(1)
    .match(/.{2}/g);

  if (rgbArr && rgbArr.length) {
    return rgbArr.map((x) => parseInt(x, 16));
  } else {
    throw new Error("Invalid hex supplied");
  }
};

export default (hex: string, alpha?: number) => {
  const rgb = hexToRGBArray(hex).join(",");
  return !!alpha ? `rgba(${rgb},${alpha})` : `rgb(${rgb})`;
};
