import generateColorRamp, {
  ColorRamp,
} from "styles/functions/colorRampGenerator/colorRamp";

const COLOR_RAMP_GENERATION_COEFFICIENT = 0.7;

const colorRampValues = {
  grey: ["#FAFAFA", "#858589", "#161617"],
  purpleGrey: ["#F7F7FC", "#737791", "#222233"],
  red: ["#FEF5F6", "#FD5254", "#471819"],
  green: ["#F2FCF9", "#09C590", "#00231B"],
  yellow: ["#FFF8ED", "#EEAD51", "#2E2516"],
  blue: ["#F2F6FD", "#467EE5", "0E192F"],
  brand: ["#F1F0FF", "#716BFF", "#1B1A3D"],
  newBrand: ["#FCFEF1", "#D9EE50", "#212504"],
  newGrey: ["#F5F5F5", "#858589", "#161617"],
};

type MAIN_COLORS = keyof typeof colorRampValues;

const colors = Object.entries(colorRampValues).reduce(
  (prev, [color, [start, target, end]]) => {
    prev[color as MAIN_COLORS] = generateColorRamp(
      start,
      target,
      end,
      COLOR_RAMP_GENERATION_COEFFICIENT
    );
    return prev;
  },
  {} as { [key in MAIN_COLORS]: ColorRamp }
);

const baseColors = {
  white: "#FFFFFF",
  offWhite: "#FCFCFC",
  black: "#000000",
  ...colors,
};

export default baseColors;
