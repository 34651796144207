import theme from "styled-theming";

import rem from "styles/functions/rem";
import { THEME_NAMES } from "./theme.constants";

export type SpacingScheme = {
  /**
   * 0px
   */
  none: string;
  /**
   * 1px
   */
  xxs1: string;
  /**
   * 2px
   */
  xxs2: string;

  /**
   * 4px
   */
  xs1: string;
  /**
   * 8px
   */
  xs2: string;
  /**
   * 12px
   */
  xs3: string;

  /**
   * 16px
   */
  sm1: string;
  /**
   * 20px
   */
  sm2: string;
  /**
   * 24px
   */
  sm3: string;
  /**
   * 32px
   */
  sm4: string;

  /**
   * 40px
   */
  md1: string;
  /**
   * 48px
   */
  md2: string;
  /**
   * 64px
   */
  md3: string;

  /**
   * 80px
   */
  lg1: string;
  /**
   * 120px
   */
  lg2: string;
  /**
   * 160px
   */
  lg3: string;

  /**
   * 200px
   */
  xl1: string;
  /**
   * 240px
   */
  xl2: string;
  /**
   * 260px
   */
  xl3: string;

  /**
   * 300px
   */
  xxl1: string;
  /**
   * 400px
   */
  xxl2: string;
  /**
   * 4px
   */
  radiusSmall: string;
  /**
   * 12px
   */
  radiusMedium: string;
  /**
   * 24px
   */
  radiusLarge: string;
  /**
   * 32px
   */
  radiusXLarge: string;
  /**
   * A number large enough that css will round the corners like a pill
   */
  radiusFull: string;
};
export type SizeKey = keyof SpacingScheme;

// NOTE: spacing is for building the app theme
// use themeSpacing instead within app
export const spacing = {
  none: "0",
  xxs1: rem(1),
  xxs2: rem(2),

  xs1: rem(4),
  xs2: rem(8),
  xs3: rem(12),

  sm1: rem(16),
  sm2: rem(20),
  sm3: rem(24),
  sm4: rem(32),

  md1: rem(40),
  md2: rem(48),
  md3: rem(64),

  lg1: rem(80),
  lg2: rem(120),
  lg3: rem(160),

  xl1: rem(200),
  xl2: rem(240),
  xl3: rem(260),

  xxl1: rem(300),
  xxl2: rem(400),

  radiusSmall: rem(4),
  radiusMedium: rem(12),
  radiusLarge: rem(24),
  radiusXLarge: rem(32),
  radiusFull: rem(1000),
};

/**
 * Get all spacing values for a given theme
 * @return Returns an object of spacing keys and their values
 * e.g {
 *   sm1: () => 12rem,
 * }
 */

const getSpacingTheme = (): SpacingScheme => {
  return Object.entries(spacing).reduce((rest, row) => {
    return Object.assign(rest, {
      [row[0]]: theme("themeSpacing", {
        [THEME_NAMES.DEFAULT]: row[1],
        [THEME_NAMES.NEW]: row[1],
      }),
    });
  }, {} as SpacingScheme);
};

const themeSpacing: SpacingScheme = getSpacingTheme();
export default themeSpacing;
