import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { createPortal } from "react-dom";

import useFixedOffset from "@polyai/common/hooks/useFixedOffset";
import useHideAfterClose from "@polyai/common/hooks/useHideAfterClose";
import useLockScroll from "@polyai/common/hooks/useLockScroll";
import { ComponentWithChildren } from "@polyai/common/types/helpers";
import { DURATION } from "@polyai/common/utils/animationDuration";
import ConditionalWrapper from "@polyai/common/utils/conditionalWrapper";

import sizes from "styles/config/sizes";

import * as Styled from "./StickySidebar.styled";

export { SidebarScrollableContent } from "./StickySidebar.styled";

type Position = "left" | "right";

export type StickySidebarProps = {
  children:
    | React.ReactNode
    | ((values: {
        isScrolling: boolean;
        scrollableRef: React.MutableRefObject<HTMLDivElement | null>;
      }) => React.ReactNode);
  id: string;
  isMainNavOpen: boolean;
  actInline?: boolean;
  overlayFullScreen?: boolean;
  open?: boolean;
  position?: Position;
  width?: number;
  offset?: number;
  className?: string;
  stickyHeader?: boolean;
  zIndex?: number;
  headerOffset?: number;
  fullWidth?: boolean;
  "data-test-id"?: string;
};

const DEFAULT_WIDTH = sizes.gridSidebarWidth;

const Overlay: ComponentWithChildren<{ open: boolean }> = ({
  open,
  children,
}) => {
  useLockScroll(open);

  return <Styled.Overlay $open={open}>{children}</Styled.Overlay>;
};

const StickySidebar = ({
  children,
  id,
  isMainNavOpen,
  actInline = true,
  overlayFullScreen,
  open = true,
  position = "left",
  width = DEFAULT_WIDTH,
  offset = 0,
  className,
  zIndex = 10,
  stickyHeader = false,
  headerOffset = sizes.headerHeight,
  fullWidth,
  ...props
}: StickySidebarProps) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const scrollableRef = useRef<HTMLDivElement | null>(null);
  const [isScrolling, setIsScrolling] = useState(false);
  const [isMoreScrollTop, setIsMoreScrollTop] = useState(0);
  const [isMoreScrollBottom, setIsMoreScrollBottom] = useState(0);
  const paddingTop = useFixedOffset(
    !overlayFullScreen ? headerOffset : 0,
    stickyHeader
  );

  const getRelativePosition = useCallback(
    (offset: number) => {
      return {
        [position]: `${offset}px`,
      };
    },
    [position]
  );

  const variants = useMemo(
    () => ({
      open: {
        ...getRelativePosition(offset),
        transition: {
          duration: DURATION.FAST,
          staggerChildren: 0.05,
        },
      },
      closed: {
        ...getRelativePosition(-width),
        transition: {
          duration: DURATION.FAST,
        },
      },
    }),
    [getRelativePosition, offset, width]
  );

  const fakeVariants = useMemo(
    () => ({
      open: {
        width: fullWidth ? "100%" : `${width}px`,
        transition: {
          duration: DURATION.FAST,
          staggerChildren: 0.05,
        },
      },
      closed: {
        width: "0px",
        transition: {
          duration: DURATION.FAST,
        },
      },
    }),
    [fullWidth, width]
  );

  useLayoutEffect(() => {
    const sidebar = scrollableRef.current ?? containerRef.current;
    if (!sidebar || stickyHeader) {
      return;
    }

    let timeout: NodeJS.Timeout | undefined;

    const handleScroll = () => {
      setIsMoreScrollTop(Math.min(80, sidebar.scrollTop));
      setIsMoreScrollBottom(
        Math.min(
          80,
          Math.abs(
            sidebar.scrollHeight - sidebar.scrollTop - sidebar.clientHeight
          )
        )
      );

      if (timeout) {
        clearTimeout(timeout);
      }
      setIsScrolling(true);
      timeout = setTimeout(() => {
        setIsScrolling(false);
      }, 500);
    };

    handleScroll();

    sidebar.addEventListener("scroll", handleScroll);
    return () => {
      sidebar.removeEventListener("scroll", handleScroll);
    };
  }, [stickyHeader]);

  const animate = useMemo(() => (open ? "open" : "closed"), [open]);

  const { onAnimationStart, onAnimationEnd } = useHideAfterClose(
    open,
    containerRef
  );

  return (
    <ConditionalWrapper
      condition={!!overlayFullScreen}
      wrapper={(children) => {
        return createPortal(
          <Overlay open={open}>{children}</Overlay>,
          document.body
        );
      }}
    >
      {actInline && !overlayFullScreen && (
        <Styled.FakeSidebar
          animate={animate}
          initial={false}
          variants={fakeVariants}
        />
      )}
      <Styled.Sidebar
        ref={containerRef}
        $isMainNavOpen={!overlayFullScreen && isMainNavOpen}
        $paddingTop={!overlayFullScreen ? paddingTop : 0}
        $position={position}
        animate={animate}
        className={className}
        id={id}
        initial={false}
        style={{ width: fullWidth ? "100%" : width, zIndex }}
        variants={variants}
        onAnimationComplete={onAnimationEnd}
        onAnimationStart={onAnimationStart}
        {...props}
      >
        {typeof children === "function"
          ? children({ isScrolling, scrollableRef })
          : children}
      </Styled.Sidebar>
      {!!isMoreScrollTop && (
        <Styled.Fader
          animate={animate}
          initial={false}
          position="top"
          style={{
            width,
            height: isMoreScrollTop,
            top: paddingTop,
          }}
          variants={variants}
        />
      )}
      {!!isMoreScrollBottom && (
        <Styled.Fader
          animate={animate}
          initial={false}
          position="bottom"
          style={{ width, height: isMoreScrollBottom }}
          variants={variants}
        />
      )}
    </ConditionalWrapper>
  );
};

export default StickySidebar;
