import React, {
  ComponentPropsWithRef as ComponentProps,
  memo,
  useMemo,
} from "react";

import ConditionalWrapper from "@polyai/common/utils/conditionalWrapper";

import Clickable from "../Clickable";
import { BaseIcon } from "../Icons/BaseIcon";

import * as Styled from "./Button.styled";

export enum ButtonVariant {
  BRAND = "brand",
  PRIMARY = "primary",
  SECONDARY = "secondary",
  GHOST = "ghost",
  SUCCESS = "success",
  DANGER = "danger",
}

export type ButtonLabelSize = "small" | "regular";

export interface ButtonProps extends ComponentProps<"button"> {
  label: string;
  variant?: keyof typeof ButtonVariant;
  inverse?: boolean;
  Icon?: typeof BaseIcon;
  iconPlacement?: "left" | "right" | "both";
  iconOnly?: boolean;
  size?: ButtonLabelSize;
  loading?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  to?: string;
}

const Button: React.FC<ButtonProps> = memo(
  ({
    label,
    variant = "PRIMARY",
    inverse = false,
    Icon,
    iconPlacement = "left",
    iconOnly,
    size = "regular",
    loading,
    disabled,
    fullWidth,
    to,
    ...props
  }) => {
    const showLeftIcon = useMemo(
      () => iconPlacement && ["left", "both"].includes(iconPlacement),
      [iconPlacement]
    );
    const showRightIcon = useMemo(
      () => iconPlacement && ["right", "both"].includes(iconPlacement),
      [iconPlacement]
    );

    const iconColor = useMemo(() => {
      if (disabled && !inverse) {
        return "iconDisabled";
      } else if (disabled && inverse) {
        return "iconInverseDisabled";
      } else if (inverse) {
        return Styled.variantToInverseButtonIconColor[variant];
      }

      return Styled.variantToButtonIconColor[variant];
    }, [disabled, inverse, variant]);

    /* The new version of the deprecated IconButton */
    if (Icon && iconOnly) {
      return (
        <Styled.IconOnlyButtonWrapper
          $inverse={inverse}
          $loading={loading}
          $size={size}
          $variant={variant}
          aria-disabled={loading || disabled}
          aria-label={loading ? "Loading" : label}
          disabled={disabled}
          {...props}
        >
          <Styled.ButtonContentContainer
            $show={loading ? false : true}
            $size="small"
          >
            <Icon color={iconColor} />
          </Styled.ButtonContentContainer>
          <Styled.LoadingIcon $show={!!loading} />
        </Styled.IconOnlyButtonWrapper>
      );
    }

    return (
      <ConditionalWrapper
        condition={!!to}
        wrapper={(children) => <Clickable to={to}>{children}</Clickable>}
      >
        <Styled.ButtonWrapper
          $fullWidth={fullWidth}
          $inverse={inverse}
          $loading={loading}
          $size={size}
          $variant={variant}
          disabled={disabled}
          {...props}
        >
          <Styled.ButtonContentContainer
            $show={loading ? false : true}
            $size={size}
          >
            {Icon && showLeftIcon && <Icon color={iconColor} />}

            <Styled.ButtonLabel
              $disabled={disabled}
              $hasIcon={!!Icon}
              $size={size}
            >
              {label}
            </Styled.ButtonLabel>

            {Icon && showRightIcon && <Icon color={iconColor} />}
          </Styled.ButtonContentContainer>
          <Styled.LoadingIcon $show={loading ? true : false} />
        </Styled.ButtonWrapper>
      </ConditionalWrapper>
    );
  }
);

export default Button;
